import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Chat = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `FMCorreia`


  return (
    <>
    <Seo title="Chat With Me" />

    <section className="get-touch-form">


    <div className="get-touch-form-div">
        <h3 className="white-font">Get In Touch</h3>
          <form className="index-get-touch-form" action="https://formsubmit.co/c3e02a7dc2ab5f7799fe1e6ea14b1e50" method="POST">
            <label for="not-a-field" className="nothing"> 
              <input className="form-input" id="not-field" type="text" name="_honey"/>
            </label>
              <input placeholder="NAME" className="form-input" id="name" required name="name" type="text"/>
              <input placeholder="EMAIL" className="form-input" required id="mail" name="email" type="email"/>
              <input placeholder="SUBJECT" className="form-input" required id="subject" name="subject" type="text"/>              
              <div><textarea onInput={function a(){
                let thisarea = document.getElementById("message")
                thisarea.style.height = "";
                thisarea.style.height = thisarea.scrollHeight + 6 + "px"}} placeholder="MESSAGE" className="form-input" required id="message" name="message" type></textarea></div>
            
            
            <input type="hidden" name="_next" value="https://fmcorreia.com/thanks"/>
            <input type="hidden" name="_captcha" value="false"/>



            <div><button className="button1" type="submit">Send</button></div>
          </form>
    </div> 
   
   
    
    </section>
    </>
  )
}

export default Chat

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
